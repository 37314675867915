import React, { useEffect, useState } from 'react';
import { compareProductAtom, pageDetail } from '@/State/global/global.store';

import { ArrowUp } from '@/Assets/icons/ArowUp';
import { BasicTrip } from '@/Lib/types/trip';
import { Button } from '../button/button';
import { CSSTransition } from 'react-transition-group';
import { CloseIcon } from '@/Assets/icons/Close';
import { CompareDesktopSkeleton } from './skeleton/compare-desktop-skeleton';
import { Modal } from '../modals/new-modal/modal';
import { ProductCompareCard } from './card';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';
import { TRIPS } from '@/Lib/graphql/queries/trip.query';
import { compareObj } from '@/Lib/types/compare';
import { useAtomValue } from 'jotai';
import { useCompare } from '@/Lib/hooks/useCompare';
import { useDevice } from '@/Lib/hooks/useDevice';
import { useLocation } from '@/Lib/hooks/useLocation';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

const CompareProducts = () => {
  const pageDetailInfo = useAtomValue(pageDetail);
  const { isLG } = useDevice();
  const isProductCompareEnabled = false;
  const { RemoveAllCompares } = useCompare();

  const { location } = useLocation();
  const router = useRouter();

  const compareProductList = useAtomValue(compareProductAtom);
  const [compares, setCompares] = useState<compareObj[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { loading, data } = useQuery<{ trips: { items: BasicTrip[] } }>(TRIPS, {
    variables: {
      ids: compareProductList?.map((item) => String(item.tripId)),
    },
    skip: !compareProductList || compareProductList.length === 0,
  });

  const removeAll = () => {
    RemoveAllCompares();
    setIsOpen(false);
  };

  const compare = () => {
    let rudderObjarray = compareProductList?.map((item) => {
      return {
        category: item.division,
        country: location.country.name,
        destination: item.destination,
        division: item.division,
        product_id: item.tripId,
      };
    });
    rudderObjarray && RudderAnalytics.compareProduct(rudderObjarray);
    setIsOpen(false);
    compareProductList &&
      router.push({
        pathname: '/compare',
        query: {
          trips: compareProductList.map((item) => item.tripId).join(','),
        },
      });
    typeof window !== 'undefined' &&
      localStorage.setItem('backUrl', window.location.pathname);
  };

  const compareTextNum = (count: number) => {
    switch (count) {
      case 0:
        return 3;
        break;
      case 1:
        return 2;
        break;
      case 2:
        return 1;
        break;
      case 3:
        return 0;
      default:
        break;
    }
  };

  useEffect(() => {
    compareProductList && setCompares(compareProductList);
    if (compareProductList && compareProductList.length === 0) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareProductList]);

  const openModalMobile = (e: any) => {
    setIsOpen(true);
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const isSpecificCompare =
      pageDetailInfo.name === 'division' &&
      pageDetailInfo.detail === 'working-holiday';
    if (
      isProductCompareEnabled &&
      compareProductList &&
      compareProductList.length > 0 &&
      isSpecificCompare
    ) {
      setIsVisible(true);
      !isLG && document.body.classList.add('risen-compare');
      isLG && document.body.classList.add('padding-body');
    } else {
      setIsVisible(false);
      !isLG && document.body.classList.remove('risen-compare');
      isLG && document.body.classList.remove('padding-body');
    }
    return () => {
      document.body.classList.remove('risen-compare');
      setIsVisible(false);
    };
  }, [isProductCompareEnabled, compareProductList, pageDetailInfo, isLG]);

  useEffect(() => {
    if (isOpen) {
      !isLG && document.body.classList.add('hide-compare');
    } else {
      !isLG && document.body.classList.remove('hide-compare');
    }
    return () => document.body.classList.add('hide-compare');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <CSSTransition
        in={isVisible && isLG}
        timeout={300}
        classNames="filter-translatey"
        unmountOnExit
      >
        <>
          <div
            className=" hidden lg:block bg-light-600  py-5 fixed bottom-0 z-400 w-full "
            style={{ boxShadow: '0px -10px 30px rgba(51, 53, 57, 0.05)' }}
          >
            <div className="max-w-6.75xl m-auto  ">
              <div className="md:mx-10 flex gap-x-10">
                <div className="flex-grow grid grid-cols-3 gap-x-5 items-center">
                  {!loading
                    ? data?.trips.items.map((item, i) => (
                        <ProductCompareCard trip={item} key={i} />
                      ))
                    : compareProductList?.map((item, index) => (
                        <CompareDesktopSkeleton key={index} />
                      ))}
                </div>
                <div className=" w-17.5rem border-l border-dashed border-dark-500 flex flex-col pl-10">
                  <div onClick={() => removeAll()} className="cursor-pointer">
                    <i className="icon-close text-dark-800 font-bold text-[8px]"></i>

                    <span className="text-sm font-semibold text-dark-800 ml-2">
                      CLEAR ALL
                    </span>
                  </div>
                  {compareProductList && compareProductList.length < 3 ? (
                    <div className="text-sm font-semibold text-dark-700 mt-4 !leading-4">
                      select up to {compareTextNum(compareProductList.length)}{' '}
                      products to compare
                    </div>
                  ) : (
                    <div className="h-4 mt-4"></div>
                  )}
                  <Button
                    clickHandler={() => compare()}
                    isDisabled={
                      compareProductList && compareProductList.length < 2
                        ? true
                        : false
                    }
                    className="w-full h-3rem"
                    buttonClassName="mt-4  text-light-600 w-full h-3rem flex justify-center items-center rounded-md"
                    text="Compare"
                    color="teal"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </CSSTransition>
      <CSSTransition
        in={isVisible && !isLG}
        timeout={300}
        classNames="filter-translatey"
        unmountOnExit
      >
        <>
          <div
            onClick={(e) => openModalMobile(e)}
            className="lg:hidden z-400 fixed bottom-5 right-5 p-2.5 bg-teal-900 text-light-600 w-9.25rem h-3.75rem flex justify-between items-center rounded-md"
          >
            <div className="flex flex-col gap-y-1.25">
              <div className="text-light-600 text-2xs !leading-[0.938rem] font-semibold">
                {compareProductList && compareProductList.length > 1
                  ? `${compareProductList.length} products`
                  : `1 product`}
              </div>
              <div className="text-light-600 text-xl !leading-5 font-semibold">
                Compare
              </div>
            </div>
            <ArrowUp color="#fff" cssClass={'rotate-180'} />
          </div>
          <Modal isOpen={isOpen} close={() => setIsOpen(false)} title="Compare">
            <div className="p-5 w-full lg:hidden">
              <div className="py-5 flex flex-col gap-y-2.5">
                {!loading
                  ? data?.trips.items.map((item, i) => (
                      <ProductCompareCard trip={item} key={i} />
                    ))
                  : compareProductList?.map((item, index) => (
                      <CompareDesktopSkeleton key={index} />
                    ))}
              </div>

              <div className="w-full">
                <Button
                  clickHandler={() => compare()}
                  buttonClassName="w-full  rounded-md !h-12 flex justify-center items-center  text-lg text-light-600 !leading-1.125rem font-semibold"
                  isDisabled={
                    compareProductList && compareProductList.length < 2
                      ? true
                      : false
                  }
                  className="w-full !h-12 "
                  color={'teal'}
                  text="Compare"
                />
              </div>

              <div
                onClick={() => removeAll()}
                className="w-full gap-x-0.5 mt-2.5 rounded-md h-12 flex justify-center items-center bg-GreyEE text-lg text-light-600  !leading-1.125rem font-semibold"
              >
                <CloseIcon color="#737882" width={15} height={15} />
                <div className="text-dark-800 text-sm !leading-6 font-semibold ml-1">
                  CLEAR ALL
                </div>
              </div>
            </div>
          </Modal>
        </>
      </CSSTransition>
    </>
  );
};
export { CompareProducts };
