import { Notification, Notifications } from '@/Lib/types/social-proof';
import { useEffect, useState } from 'react';

import { NotificationHelper } from './notification.helper';
import { NotificationStorageHelper } from './notification-storage.helper';

// TODO: add a sentry error when url is empty
const url = process?.env?.NEXT_PUBLIC_SOCIAL_PROOFS_SERVICE_DOMAIN_NAME
  ? `https://${process?.env?.NEXT_PUBLIC_SOCIAL_PROOFS_SERVICE_DOMAIN_NAME}`
  : '';

const useBookedCustomerData = () => {
  const [data, setData] = useState<Notifications | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = async (): Promise<Notifications> => {
    const response = await fetch(url);
    if (!response.ok) {
      console.error('Fetching notifications from social-proofs server failed.');
      return [];
    }

    const result = (await response.json()) as Notifications;
    const data = result.filter((c) => c.name.toLowerCase() !== 'test') ?? [];

    const anchors = NotificationStorageHelper.get();
    if (!anchors || anchors.first !== data[0].hash) {
      NotificationStorageHelper.set({ first: data[0].hash });
      return data;
    }

    return NotificationHelper.removeAlreadySeenEntries(data);
  };

  const updateSeenAnchor = (next: Notification) => {
    NotificationStorageHelper.set({ last: next.hash });
  };

  const refetch = () => {
    NotificationStorageHelper.reset();

    setIsFetching(true);
    fetchData().then((data) => {
      setData(data);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    // prevent fetching on server
    if (typeof window === 'undefined') return;

    setIsLoading(true);
    fetchData().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  return { data, refetch, isLoading, isFetching, updateSeenAnchor };
};

export { useBookedCustomerData };
