import '../styles/globals.css';

import React, { useEffect, useRef } from 'react';

import { ApolloProvider } from '@apollo/client';
import { AppPropsWithLayout } from '@/Lib/types/app';
import { CookiesProvider } from 'react-cookie';
import { GlobalStateProvider } from '@/State/global/global.provider';
import MainLayout from '../layouts/MainLayout';
import { Meta } from '@/Components/meta/meta';
import { ScrollProvider } from '@/Lib/contexts/ScrollContext';
import { SharedPageStaticData } from '@/Lib/types/page';
import { SharedStaticDataProvider } from '@/Lib/hooks/static-data/shared-static-data.provider';
import { StructuredData } from '@/Components/structured-data/structured-data';
import { ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import { useApollo } from '@/Lib/graphql/apollo-client';
import { useRouter } from 'next/router';
import { useRudderstackPage } from '@/Lib/hooks/rudderstack/useRudderstackPage';
import { useSetupIntercom } from '@/Lib/hooks/useSetupIntercom';

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const hasParamsSaved = useRef(false);
  const params = useRouter().asPath.split(/\?/)[1];
  const query = queryString.parse(params);

  useSetupIntercom();
  useRudderstackPage();

  const Layout = Component.Layout || MainLayout;

  if (
    typeof window !== 'undefined' &&
    !hasParamsSaved.current &&
    Object.keys(query).length
  ) {
    hasParamsSaved.current = true;
  }

  if (typeof window !== 'undefined' && query?.['asFrame']) {
    document.body.classList.add('as-frame');
  }

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      import('intersection-observer');
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <SharedStaticDataProvider<SharedPageStaticData>
        data={{
          navigationData: pageProps.navigationData,
          headerData: pageProps.headerData,
          offices: pageProps.offices,
        }}
      >
        <GlobalStateProvider>
          <CookiesProvider>
            <Meta data={pageProps.meta} />
            <StructuredData />
            <ScrollProvider>
              <Layout pageProps={pageProps}>
                <Component {...pageProps} />
                <ToastContainer />
              </Layout>
            </ScrollProvider>
          </CookiesProvider>
        </GlobalStateProvider>
      </SharedStaticDataProvider>
    </ApolloProvider>
  );
}
export default MyApp;
