import { TCurrency, TCurrencyObject } from '@/Lib/types/currency';

export const currencies: {
  [key: string]: TCurrency;
} = {
  Americas: 'USD',
  Europe: 'EUR',
  Ocenia: 'AUD',
  AU: 'AUD',
  CA: 'CAD',
  US: 'USD',
  GB: 'GBP',
  NZ: 'NZD',
  default: 'USD',
};

export const currencySymbols = {
  AUD: '$',
  CAD: '$',
  EUR: '€',
  GBP: '£',
  NZD: '$',
  USD: '$',
};

export const allCurrencies: TCurrencyObject[] = [
  {
    code: 'AUD',
    country: {
      code: 'au',
      name: 'Australia',
    },
    description: 'Australian Dollar',
  },
  {
    code: 'NZD',
    country: {
      code: 'nz',
      name: 'New Zealand',
    },
    description: 'New Zealand Dollar',
  },
  {
    code: 'GBP',
    country: {
      code: 'gb',
      name: 'United Kingdom',
    },
    description: 'Pound Sterling',
  },
  {
    code: 'EUR',
    country: {
      code: 'eu',
      name: 'Euro',
    },
    description: null,
  },
  {
    code: 'USD',
    country: {
      code: 'us',
      name: 'United States',
    },
    description: 'United States Dollar',
  },
  {
    code: 'CAD',
    country: {
      code: 'ca',
      name: 'Canada',
    },
    description: 'Canadian Dollar',
  },
];

export const euroCountries = [
  'AT',
  'BE',
  'HR',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'SK',
  'SI',
  'ES',
];
