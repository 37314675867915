import { DownloadIcon } from '@/Assets/icons/download';
import { InfoModal } from '@/Components/FPDC/info-modal';
import { TSimpleUser } from '@/DataTypes/user';
import { getUserInfoFPDC, setUserInfoFPDC } from '@/Lib/helpers/fpdc';
import { useState } from 'react';
import { saveAs } from 'file-saver';

const CTADownload = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleDownload = async () => {
    try {
      // Fetch the file as a blob
      const response = await fetch(
        'https://gwatco-res.cloudinary.com/image/upload/v1737540374/website/static/Ultimate_Work_Travel_Guide.pdf'
      );

      if (!response.ok) {
        throw new Error('File download failed');
      }

      const blob = await response.blob();

      // Use FileSaver.js to save the file
      saveAs(blob, 'ULTIMATE-WORK-TRAVEL-GUIDE.pdf');
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const afterLogin = (user: TSimpleUser) => {
    handleDownload();
    setIsModalOpen(false);
    setUserInfoFPDC(user);
  };

  const Download = () => {
    const getUserInfo = getUserInfoFPDC();
    if (getUserInfo) {
      handleDownload();
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <>
      <InfoModal
        isOpenModal={isModalOpen}
        setIsOpenModal={setIsModalOpen}
        afterLogin={afterLogin}
        type="where-should-i-go-form"
      />
      <div
        onClick={Download}
        className=" w-[9.688rem] md:w-[11.25rem] h-8 md:h-10 bg-[#FFC038] flex  justify-center items-center rounded-full gap-x-1 cursor-pointer"
      >
        <div className=" text-2xs md:text-sm font-semibold text-black ">
          What should I book?!
        </div>
        <DownloadIcon />
      </div>
    </>
  );
};
export { CTADownload };
