import { GImage, generateUrl } from '../g-image';
import React, { useEffect, useState } from 'react';

import { AppLink } from '@/Components/link/link';
import { AppStoreBtn } from './AppStoreBtn';
import { FooterBottom } from './bottom/bottom';
import { FooterBottomOld } from './bottom/old';
import { FooterBrands } from './brands';
import { GooglePlayBtn } from './GooglePlayBtn';
import { SubscribeForm } from './subscribe/subscription-modal';
import { isInTripPageAtom } from '@/State/global/global.store';
import { useAtomValue } from 'jotai';
import { useCurrency } from '@/Lib/hooks/useCurrency';

const Footer = () => {
  const [hasExtraBottomMargin, setHasExtraBottomMargin] =
    useState<boolean>(false);
  const isInTripPage = useAtomValue(isInTripPageAtom);
  const { currencyIsChangeable } = useCurrency();

  useEffect(() => {
    if (isInTripPage) {
      setHasExtraBottomMargin(true);
    } else {
      setHasExtraBottomMargin(false);
    }
  }, [isInTripPage]);

  return (
    <>
      <footer
        className={
          'items-center pt-12 border-t border-grey block ' +
          (hasExtraBottomMargin ? 'mb-7.187rem lg:mb-0' : '')
        }
      >
        <div className="max-w-6.75xl m-auto">
          <div className="flex flex-col lg:flex-row gap-y-5 px-5 lg:gap-x-8 xl:mx-10 lg:mx-7">
            <div className="lg:text-left lg:m-0 text-center lg:min-w-22rem lg:max-w-22rem justify-self-center self-center">
              <h3 className="text-xl leading-6 font-semibold mb-1">
                Want to know a secret or two?
              </h3>
              <p className="text-base mb-2 text-dark-600 leading-5">
                Join our inner circle of over 1 million to find out the tips &
                tricks of the better way to travel.
              </p>
              <SubscribeForm />

              <div className="flex justify-between lg:justify-start items-center px-2.5 lg:px-0 mt-8 flex-grow">
                <a
                  aria-label="instagram"
                  href="https://www.instagram.com/globalworkandtravel/"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-instagram text-2.5xl lg:text-xl text-dark-700 lg:mr-5"></i>
                </a>
                <a
                  aria-label="tiktok"
                  href="https://www.tiktok.com/@globalworkandtravel"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-tik-tok text-2.5xl lg:text-xl text-dark-700 lg:mr-5"></i>
                </a>
                <a
                  aria-label="facebook"
                  href="https://www.facebook.com/globalworkandtravel"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-facebook1 text-2.5xl lg:text-xl text-dark-700 lg:mr-5"></i>
                </a>
                <a
                  aria-label="youtube"
                  href="https://www.youtube.com/user/gwatsup"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-youtube text-2.5xl lg:text-xl text-dark-700 lg:mr-5 relative top-[2px]"></i>
                </a>
                <a
                  aria-label="pinterest"
                  href="https://pinterest.com/thegwatco/"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-pinterest text-2.5xl lg:text-xl text-dark-700 lg:mr-5"></i>
                </a>
                <a
                  aria-label="linkedin"
                  href="https://www.linkedin.com/company/the-global-work-&-travel-co-"
                  target="_blank"
                  rel="noreferrer"
                  className="leading-none"
                >
                  <i className="icon-linkedin text-2.5xl lg:text-xl text-dark-700 lg:mr-5"></i>
                </a>
              </div>
            </div>

            <div className=" grid grid-cols-2 lg:h-12rem lg:max-h-12rem flex-grow w-full">
              <AppLink
                href="/parents"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Parents
              </AppLink>
              <AppLink
                href="/about-us"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                About us
              </AppLink>
              <AppLink
                href="/partners"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Partners
              </AppLink>
              <AppLink
                href="/contact"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Contact us
              </AppLink>
              <AppLink
                href="/careers"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Careers
              </AppLink>
              <AppLink
                href={{
                  pathname: '/terms-and-conditions',
                  query: { tab: 'bc' },
                }}
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Booking conditions
              </AppLink>
              <AppLink
                href="/affiliate"
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Affiliates
              </AppLink>
              <AppLink
                href={{
                  pathname: '/terms-and-conditions',
                  query: { tab: 'pp' },
                }}
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Privacy policy
              </AppLink>
              <AppLink
                href={{
                  pathname: '/reviews',
                }}
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Reviews
              </AppLink>

              <AppLink
                href={{
                  pathname: '/sitemap/v1',
                }}
                className="my-2 text-dark-700 cursor-pointer text-base leading-6 flex justify-start items-center"
              >
                Sitemap
              </AppLink>
            </div>
            <div className="flex items-center justify-center w-full lg:w-7rem  lg:h-12rem lg:max-h-12rem">
              <a
                href="https://www.iata.org/"
                target="_blank"
                rel="noreferrer"
                aria-label="iata"
              >
                <div className="lg:w-28 w-20">
                  <GImage
                    path="website/logo/iata-logo.svg"
                    alt="Iata"
                    height="auto"
                    width="100%"
                    classes=""
                  />
                </div>
              </a>
            </div>
            <div className="flex flex-col gap-y-5 pt-5 lg:pt-0 text-center lg:text-left lg:h-12rem lg:max-h-12rem lg:pl-10 lg:border-l lg:border-grey">
              <div className="text-sm font-semibold !leading-6 text-pureblack ">
                Download Our Free Mobile App
              </div>
              <div className="flex flex-col md:items-center gap-y-2.5 lg:gap-y-5">
                <AppStoreBtn />
                <GooglePlayBtn />
              </div>
            </div>
          </div>
        </div>
        <FooterBrands />
        {currencyIsChangeable ? <FooterBottom /> : <FooterBottomOld />}
      </footer>
    </>
  );
};
export default Footer;
